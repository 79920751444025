import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react"

import Image0 from "../images/multicar0.jpeg"
import Image1 from "../images/multicar1.jpg"
import Image2 from "../images/multicar2.jpg"
import Image3 from "../images/multicar3.jpg"
import Image4 from "../images/multicar4.jpg"
import Image5 from "../images/multicar5.jpg"
import Image6 from "../images/multicar6.jpg"
import Image7 from "../images/multicar7.jpg"
import Image8 from "../images/multicar8.jpg"

const AutoplaySlider = withAutoplay(AwesomeSlider)

const images = [
  Image0,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
]

const IndexPage = props => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState({})

  const onMarkerClick = (props, marker) => {
    setSelectedPlace(props)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onClose = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
    }
  }

  return (
    <Layout>
      <SEO title="Maloobjemové kontejnery Teplice" />
      <Content>
        <p>
          Zajišťuji <span>odvoz a&nbsp;dovoz odpadu a materiálu</span> (uhlí,
          písek, štěrk atd.), možnost i&nbsp;paletového zboží. <br />
          Kontejnery jsou o&nbsp;objemu <span>1,5 až 3,5 kubíku</span>{" "}
          <span className="small">
            (&nbsp;1,5&nbsp;m
            <sup>3</sup>, 2,5&nbsp;m<sup>3</sup> a&nbsp;3,5&nbsp;m<sup>3</sup>
            &nbsp;)
          </span>
          , o&nbsp;hmotnosti max. 2&nbsp;tuny.
        </p>
        <p>
          Kontejnery dopravujeme vozy multicar, které jsou vybaveny pohonem 4x4,
          díky kterému zdoláme jakýkoliv terén a&nbsp;
          <span>
            kontejnery přístavíme kamkoliv, kam se velké kontejnery nedostanou.
          </span>
        </p>
        <p>
          Dále nabízíme zemní práce s <span>minibagrem</span> a{" "}
          <span className="new">nově i dopravu kontejnerů do 3,5 tuny</span>
        </p>
      </Content>
      <Slider play={true} interval={5000}>
        {images.map(image => (
          <div data-src={image} alt="Multicar" key={image} />
        ))}
      </Slider>
      <MapBlock id="map">
        <Map
          google={props.google}
          style={mapStyles}
          initialCenter={{ lat: 50.661541, lng: 13.852931 }}
          zoom={15}
        >
          <Marker
            onClick={onMarkerClick}
            position={{ lat: 50.661541, lng: 13.852931 }}
            name={
              "Maloobjemové Kontejnery Teplice - Pod Kopcem 42, Teplice 41510"
            }
          />
          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow}
            onClose={onClose}
          >
            <div>
              <p>
                <strong>{selectedPlace.name}</strong>
              </p>
            </div>
          </InfoWindow>
        </Map>
      </MapBlock>
    </Layout>
  )
}

const Content = styled.div`
  background: #fff;
  padding: 35px;
  font-size: 1.7em;
  font-weight: 500;
  color: #000;
  span {
    color: #008044;
    font-weight: 600;
  }
  .small {
    font-size: 0.9em;
    color: #000;
    font-weight: 500;
  }
  .new {
    color: #e36900;
  }
`

const Slider = styled(AutoplaySlider)`
  margin-top: 20px;
  --organic-arrow-color: #fff;
  --control-bullet-color: #00493b;
  --control-bullet-active-color: #e36900;
  --loader-bar-color: #f37c30;
`
const MapBlock = styled.div`
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
`
const mapStyles = {
  height: "40vh",
  marginTop: "50px",
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsUxUAHK2WtOLzdtujBlMRcFARZli47LQ",
})(IndexPage)
